import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';
import * as $ from 'jquery';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {
  menuItems: any[] = [];
  activeUrl: string;
  eventsSub: Subscription;
  showLogo = true;

  @ViewChild('stickyMenu', { static: false }) stickyMenu: ElementRef;
  @ViewChild('stikyMenuMobile', { static: false }) stikyMenuMobile: ElementRef;


  hidden: boolean = true;
  hiddenMobile: boolean = true;
  elementPosition: any;
  elementMobilePosition: any;
  servicePosition: any;
  isMenuOpen: boolean = false;  

  constructor(private router: Router) { }

  ngOnInit() {
    this.menuItems.push({
      path: 'carousel-item',
      name: 'HOME'
    });
    this.menuItems.push({
      path: 'services-container',
      name: 'SERVIZI'
    });
    this.menuItems.push({
      path: 'chi-siamo-container',
      name: 'CHI SIAMO'
    });
    this.menuItems.push({
      path: 'footer-container',
      name: 'CONTATTI'
    })

    this.activeUrl = this.router.url;
  }

  ngAfterViewInit() {
    this.elementPosition = this.stickyMenu.nativeElement.offsetTop;
    this.elementMobilePosition = this.stikyMenuMobile.nativeElement.offsetTop;
  }


  private lastScroll = 0;

  @HostListener('window:scroll', [])
  handleScroll() {
    const currentScroll = window.pageYOffset;
    if (Math.abs(currentScroll - this.lastScroll) > 50) {  // scroll threshold
      this.lastScroll = currentScroll;
      this.updateNavbarVisibility(currentScroll);
    }
  }

  private updateNavbarVisibility(windowScroll: number) {
    this.hidden = windowScroll < this.elementPosition;
    this.hiddenMobile = windowScroll < this.elementMobilePosition;
  }



  ngOnDestroy() {
    this.eventsSub.unsubscribe();
  }



  goToByScroll(e) {
    var match = window.matchMedia("(max-width: 720px)");
    const targetElement = document.querySelector(e) as HTMLElement;

    if (targetElement) {
        // Ottieni l'altezza della navbar
        const navbar = document.querySelector('.navbar-mobile') as HTMLElement ||
                       document.querySelector('.navbar-container') as HTMLElement;

        const navbarHeight = navbar ? navbar.offsetHeight : 0;

        // Calcola la posizione di scroll
        const targetPosition = targetElement.getBoundingClientRect().top + window.scrollY - navbarHeight - 170;

        // Esegui lo scroll
        window.scrollTo({
            top: targetPosition,
            behavior: 'smooth'
        });
    }
    // Chiudi il menu mobile dopo il click su un elemento
    if (match.matches) {
      var x = this.hiddenMobile ? 
          document.getElementById("mobile-navbar-items") : 
          document.getElementById("mobile-navbar-items-sticky");
  
      if (x.style.display === "block") {
        x.style.display = "none";
        this.isMenuOpen = false;  // Imposta lo stato del menu a chiuso
      }
    }
  }
  


  openMenu() {
    var x = this.hiddenMobile ? 
        document.getElementById("mobile-navbar-items") : 
        document.getElementById("mobile-navbar-items-sticky");
  
    if (x.style.display === "block") {
      // Nascondi il menu se è visibile
      x.style.display = "none";
      this.isMenuOpen = false;  // Aggiorna lo stato del menu
    } else {
      // Mostra il menu e fai scroll per renderlo visibile
      x.style.display = "block";
      this.isMenuOpen = true;  // Aggiorna lo stato del menu
  
      // Forza lo scroll verso il menu in modalità sticky, se necessario
      if (this.hiddenMobile === false) {
        $('html, body').stop().animate({
            scrollTop: $(x).offset().top - 50  // Scorri verso la navbar mobile sticky
        }, 'slow');
      }
    }
  }
}
