import { Directive, HostListener, Input , ElementRef, Renderer2, AfterViewInit} from '@angular/core';

@Directive({
  selector: '[appTrackScroll]'
})
export class TrackScrollDirective {


  @Input("appTrackScroll")
  private scrollName; 

  state = 'hide'

  constructor(public el: ElementRef, private renderer: Renderer2) { 
     
  }

  ngAfterViewInit(){
     this.highlightBox();
  }

  @HostListener('window:scroll', [])
    checkScroll() {
       this.highlightBox();
    }

    highlightBox() {
      const componentPosition = this.el.nativeElement.offsetTop; // posizione dell'elemento
      const componentHeight = this.el.nativeElement.offsetHeight; // altezza dell'elemento
      const scrollPosition = window.pageYOffset; // posizione di scroll attuale
      const windowHeight = window.innerHeight; // altezza della finestra
  
      // Rimuovi la classe highlight da tutti gli altri elementi
      const allElements = document.querySelectorAll('[appTrackScroll]');
      allElements.forEach((element) => {
          this.renderer.removeClass(element, 'highlight');
      });
  
      // Calcola i limiti per l'evidenziazione
      const startVisible = componentPosition - (windowHeight / 2);
      const endVisible = componentPosition + componentHeight - (windowHeight / 2);
  
      if (scrollPosition >= startVisible && scrollPosition < endVisible) {
        this.renderer.addClass(this.el.nativeElement, 'highlight');
    } else {
        this.renderer.removeClass(this.el.nativeElement, 'highlight');
    }
    
  }
  
  

}