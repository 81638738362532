import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, Injector } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'; 

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavbarComponent } from './navbar/navbar.component';
import { registerLocaleData } from '@angular/common';

import { initApp } from './services/app-initializer';

import {
  TranslateModule,
  TranslateLoader,
  TranslateService
} from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';

import {
  HttpClientModule,
  HttpClient
} from '@angular/common/http';
import { FooterComponent } from './footer/footer.component';
import {SlideshowModule} from 'ng-simple-slideshow';
import { CarouselComponent } from './carousel/carousel.component';
import { ServiziComponent } from './servizi/servizi.component';
import { TrackScrollDirective } from './track-scroll.directive';
import { ChiSiamoComponent } from './chi-siamo/chi-siamo.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new MultiTranslateHttpLoader(http, [
    { prefix: './assets/i18n/', suffix: '.json' }
  ]);
}


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavbarComponent,
    FooterComponent,
    CarouselComponent,
    ServiziComponent,
    TrackScrollDirective,
    ChiSiamoComponent
  ],
  imports: [
    NgbModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    HttpClientModule,
    SlideshowModule
  ],
  providers: [ {
    provide: APP_INITIALIZER,
    useFactory: initApp,
    deps: [TranslateService, Injector],
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
