import { Component, OnInit } from '@angular/core';
declare function openTab(any): any;

@Component({
  selector: 'app-servizi',
  templateUrl: './servizi.component.html',
  styleUrls: ['./servizi.component.scss']
})
export class ServiziComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  
  }

  public services = [
  {
    name : "Tagliamo",
    img: "../../assets/img/home_1.jpg",
    desc: "Tagliamo tutta la carta che vuoi",
    id: "id1"
  },
  {
    name : "Impacchettiamo",
    img: "../../assets/img/home_1.jpg",
    desc: "Impacchettiamo tutta la carta che vuoi",
    id: "id2"
  },
  {
    name : "Spediamo",
    img: "../../assets/img/home_1.jpg",
    desc: "Spediamo tutta la carta che vuoi",
    id: "id3"
  },
] ;


open(section){
  console.log("expandInfo");
  // console.log(section);

  openTab(section);
}

closeDesc(id)
{
  var x = document.getElementById(id);
  
  x.style.display = "none";
}


}
