import { Injector, isDevMode } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LOCATION_INITIALIZED } from '@angular/common';
import * as moment from 'moment';

export function initApp(translate: TranslateService, injector: Injector) {
  return () =>
    new Promise<any>((resolve: any) => {
      const locationInitialized = injector.get(
        LOCATION_INITIALIZED,
        Promise.resolve(null)
      );
      locationInitialized.then(() => {
        const lang = 'en';
        // if (!isDevMode()) {
        //   lang = translate.getBrowserLang().substr(0, 2);
        // }
        moment.locale(lang);
        translate.setDefaultLang('en');
        translate.use(lang).subscribe(
          () => {
            // tslint:disable-next-line: no-console
            console.info(`'${lang}' language succesfully initialized.'`);
          },
          () => {
            console.log(`'${lang}' language initialization failed.'`);
          },
          () => {
            resolve(null);
          }
        );
      });
    });
}
