
import { Component, OnInit } from '@angular/core';  
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';  
@Component({  
  selector: 'app-carousel',  
  templateUrl: './carousel.component.html',  
  styleUrls: ['./carousel.component.scss']  
})  
export class CarouselComponent implements OnInit {  
  
  constructor(config: NgbCarouselConfig) {  
    config.interval = 2000;  
    config.wrap = true;  
    config.keyboard = false;  
    config.pauseOnHover = false;  
  }  
  ngOnInit() {  
    
  }  
  

  goToByScroll() {
    const element = document.getElementById('services-title');
    if (element) {
      window.scrollTo({
        top: element.offsetTop - 60,
        behavior: 'smooth'
      });
    }
  }
  


  
  // images = [1,2].map((n) => `../../assets/img/home_${n}.jpg`);
  images = ["../../assets/img/home_1.jpg", "../../assets/img/particolare_1.jpg", "../../assets/img/particolare_2.jpg", "../../assets/img/particolare_3.jpg"];

}  
